

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

import ContainerCard from '@/components/ContainerCard.vue'

import RegisterCompany from '@/graphql/companyService/RegisterCompany.gql'
import { IRegisterCompanyData } from '@/graphql/companyService/interfaces/registerCompany.interface'

@Component<RegisterForm>({
  components: {
    ContainerCard
  }
})
export default class RegisterForm extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }
  newCompany = {
    name: '',
    contactPerson: '',
    phone: '',
    email: '',
    zip: '',
    street: '',
    country: '',
    city: '',
    recipient: '',
    iban: '',
    bic: ''
  }
  termsChecked = false

  async submit() {
    const valid = await this.$refs.observer.validate()
    if (!valid) { return }
    try {
      const { data } = await this.$apollo.mutate<IRegisterCompanyData>({
        mutation: RegisterCompany,
        variables: this.newCompany
      })
      if (data && data.registerNewCompany === 'success') {
        this.$emit('advance')
      } else {
        this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
      }
    } catch(error) {
      this.$toast.open({ type: 'error', message: `${this.$t('toast.errors.' + error.message)}` })
    }
  }
}
